import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretLeft,
  faCaretRight,
  faMinus,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import GlobalStyle from 'styles/GlobalStyle';
import { mobile } from 'styles/responsive';
import { blinkCursor, blinkText, typing } from 'styles/mixins';

const NotFoundPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--error-wrap-bg);

  /* prevent drag */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  border: 1px solid var(--error-point);
  background: var(--error-content-bg);
  box-shadow: 10px 10px 0 0 var(--error-point);
  transition: 0.5s;

  @media (max-width: ${mobile}) {
    width: 70%;
  }
`;

const NotFoundHeader = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 20px;
  border-bottom: 1px solid var(--error-point);

  svg {
    margin-left: 20px;
    color: var(--error-point);
  }

  @media (max-width: ${mobile}) {
    padding: 10px;
    svg {
      margin-left: 10px;
      font-size: 15px;
    }
  }
`;

const NotFoundMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px 0;
  text-align: center;

  .fa-triangle-exclamation {
    color: var(--error-point);
    font-size: 80px;
  }

  @media (max-width: ${mobile}) {
    margin: 20px 0;
    .fa-triangle-exclamation {
      font-size: 40px;
    }
  }
`;

const NotFoundText = styled.div`
  @font-face {
    font-family: 'HBIOS-SYS';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2207-01@1.0/HBIOS-SYS.woff2')
      format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NeoDunggeunmo';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.3/NeoDunggeunmo.woff')
      format('woff');
    font-weight: normal;
    font-style: normal;
  }

  color: var(--error-point);
  font-family: 'HBIOS-SYS';
  font-size: 150px;
  text-shadow: 2px 1px #ffffff;

  @media (max-width: ${mobile}) {
    font-size: 60px;
  }
`;

const NotFoundDescription = styled.div`
  display: inline-block;
  overflow: hidden;
  border-right: 3px solid var(--error-point);
  color: #ffffff;
  font-family: 'HBIOS-SYS';
  font-size: 26px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 4px;
  animation: ${typing} 1.5s steps(20), ${blinkCursor} 0.5s step-end infinite;

  @media (max-width: ${mobile}) {
    font-size: 18px;
  }
`;

const GoToMainBtn = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 30px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  letter-spacing: 4px;

  p {
    margin: 0 20px;
    font-family: 'HBIOS-SYS';
    font-size: 24px;
  }
  svg {
    color: #ffffff;
    font-size: 20px;
  }

  &:hover {
    color: var(--error-point);
    text-shadow: 1px 0.5px #ffffff;

    p {
      animation: ${blinkText} 0.8s step-end infinite;
    }
  }

  @media (max-width: ${mobile}) {
    margin-top: 15px;
    p {
      font-size: 18px;
    }
  }
`;

export default function NotFoundPage() {
  return (
    <NotFoundPageWrapper>
      <GlobalStyle />
      <NotFoundContainer>
        <NotFoundHeader>
          <FontAwesomeIcon icon={faMinus} />
          <FontAwesomeIcon icon={faWindowRestore} />
          <FontAwesomeIcon icon={faXmark} />
        </NotFoundHeader>
        <NotFoundMainWrapper>
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </div>
          <div className="text-wrapper">
            <NotFoundText>404</NotFoundText>
            <NotFoundDescription>Page Not Found</NotFoundDescription>
          </div>
          <GoToMainBtn to="/">
            <FontAwesomeIcon icon={faCaretRight} />
            <p>To Main</p>
            <FontAwesomeIcon icon={faCaretLeft} />
          </GoToMainBtn>
        </NotFoundMainWrapper>
      </NotFoundContainer>
    </NotFoundPageWrapper>
  );
}
